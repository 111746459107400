<template>
  <div>
    <validation-observer ref="observer">
      <FormInput
          rules="required"
          :value="isEdit? standard.starting_weight :''"
          label="ນ້ຳໜັກເລິ່ມຕົ້ນ"
          @input="starting_weight=$event"
          name="starting weight"
          type="number"
          :disabled="isEdit?disabled:false"
          placeholder="ປ້ອນຂໍ້ມູນ..."
          icon="fal fa-balance-scale"/>
    </validation-observer>
   <div>
     <img src="./" alt="">
   </div>
  </div>
</template>


<script>
import {extend, setInteractionMode} from "vee-validate"
import {required} from "vee-validate/dist/rules"

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{field}',
});

export default {
  name: "index",
  data(){
    return {
      svg:'@/assets/logo.svg'
    }
  }
}
</script>

<style scoped>

</style>
